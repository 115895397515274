import { FunctionComponent, ReactNode } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

interface Props {
  handleClose?: () => void;
  isModalOpen: boolean;
  children: ReactNode;
}

const Popup: FunctionComponent<Props> = ({
  handleClose,
  isModalOpen,
  children,
}) => {
  return (
    <Modal
      className="flex justify-center items-center px-4"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 800,
      }}
    >
      <Fade in={isModalOpen}>
        <div className="relative flex items-center justify-center rounded-6 md:mx-auto md:max-w-5xl w-full">
          <div className="w-full">{children}</div>
        </div>
      </Fade>
    </Modal>
  );
};

export default Popup;
